import React, { useEffect, useState } from "react";
import axios from "axios";

function useUserProfile({ email }) {
  const [user, setUser] = useState(null);

  async function getData() {
    await axios
      .post(process.env.REACT_APP_API_URL, {
        query: "getProfile",
        email: email,
      })
      .then((response) => {
        setUser(response.data.body[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getData();
  }, [email]);

  return user;
}

export default useUserProfile;
