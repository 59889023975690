import React, { useState, useEffect, useRef } from "react";
import Assets from "@/components/Assets";
import axios from "axios";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";

function ShareTools({ sender, senderName, open, close, code }) {
  const [state, setState] = useState({
    name: "",
    email: "",
    topic: "",
    errors: {
      message: "",
      type: null,
    },
    sender: sender,
    senderName: senderName,
    status: 0,
    opened: open,
  });
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const [copiedText, copy] = useCopyToClipboard();
  const [btnText, setBtnText] = useState("Copy Link");

  //Validate each of the values
  function validate() {
    return [state.email, state.name].every((type) => {
      if (type == "") {
        setState({
          ...state,
          errors: {
            message:
              "Whoops! 🫣 You need to fill out all the criteria to share a Quick Tool.",
            type: type,
          },
        });
        return false;
      }
      return true;
    });
  }

  useEffect(() => {
    setState({
      ...state,
      errors: {
        message: "",
        type: null,
      },
    });
  }, [state.name, state.email]);

  function handleSubmit(e) {
    e.preventDefault();
    //If we don't have any empty values
    if (validate()) {
      //Validate the email
      const v = String(state.email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      //
      if (v) {
        axios
          .post(process.env.REACT_APP_EMAIL_URL, {
            query: "shareTools",
            email: state.email,
            name: state.name,
            sender: state.sender,
            senderName: state.senderName,
            challenge: state.topic,
            link: `${
              process.env.REACT_APP_URL
            }/share?email=${sender}&category=Tool&name=${state.name.replace(
              /\s+/g,
              "_"
            )}&code=${code}&details=${state.topic}`,
          })
          .then(function (response) {
            if (response?.data?.body === "Email Sent") {
              setState({
                ...state,
                status: 1,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        //Set it as an invalid email
        setState({
          ...state,
          errors: {
            message: "Whoops! 🫣 You need to input a valid email address",
            type: "email",
          },
        });
      }
    }
  }

  const handleCopy = (text) => () => {
    if (state.name !== "" && state.email !== "") {
      copy(text)
        .then(() => {
          setBtnText("Copied");
        })
        .catch((error) => {
          setBtnText("Failed to copy!");
        });
    } else {
      setState({
        ...state,
        errors: {
          message:
            "Whoops! 🫣 You need to fill out all the criteria to share a Quick Tool.",
        },
      });

      if (state.name === "") {
        nameRef.current.focus();
      } else if (state.email === "") {
        emailRef.current.focus();
      }
      return false;
    }
  };

  return (
    <div id="toolsShare" className="shareModal">
      <div className="Banner-Image-Wrapper">
        <img src={Assets["qts-banner"]} alt="tools" />
      </div>

      <div className="share-header">
        <div>
          <h2>
            Contractor <br />
            Quick Tools
          </h2>
          <h3>
            You know and love BTA templates... now you can share them with a
            friend.
          </h3>
        </div>
      </div>

      <div className="content">
        {state.status === 1 && (
          <div className="success">
            <div className="icon">🙌</div>
            <h2>Thanks for sharing</h2>
            <p>
              Thanks for sharing BTA Content with a friend and helping a fellow
              contractor systemize their business.
            </p>
            <button className="button" onClick={close}>
              10 BTA Points Earned
            </button>
          </div>
        )}
        {state.status === 0 && (
          <>
            <form onSubmit={handleSubmit}>
              <div>
                <p>
                  <strong>Pick the tool you think fits them best</strong>
                </p>
                <select
                  name="topic"
                  onChange={(e) =>
                    setState({ ...state, topic: e.target.value })
                  }
                >
                  <option value="">Select a tool</option>
                  <option value="ideal_candidate_profile">
                    Ideal Candidate Profile
                  </option>
                  <option value="interview_guide">Interview Guide</option>
                  <option value="training_checklist">Training Checklist</option>
                  <option value="strategic_plan">Strategic Plan</option>
                  <option value="tech_stack">Tech Stack</option>
                  <option value="interview_setup_call">
                    Interview Setup Call
                  </option>
                  <option value="employment_agreements">
                    Employment Agreements
                  </option>
                  <option value="budget">Budget</option>
                  <option value="reclaim">Reclaim 5 Hours Per Week</option>
                  <option value="compensation_incentives">
                    Compensation & Incentives
                  </option>
                </select>
              </div>

              <div>
                <input
                  type="text"
                  name="name"
                  ref={nameRef}
                  value={state.name}
                  className={state.errors.type === "name" ? "error" : ""}
                  onChange={(e) => setState({ ...state, name: e.target.value })}
                  placeholder="Name *"
                />
                <input
                  type="email"
                  name="email"
                  ref={emailRef}
                  value={state.email}
                  className={state.errors.type === "email" ? "error" : ""}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  placeholder="Email *"
                />
              </div>

              <div>
                <input type="submit" className="Button" value="Send Email" />
                {state.errors.message && (
                  <p className="error">{state.errors.message}</p>
                )}
              </div>
            </form>

            <div className="shareCopy">
              <input
                type="text"
                disabled
                value={`${
                  process.env.REACT_APP_URL
                }/share?email=${sender}&category=Tool&name=${state.name.replace(
                  /\s+/g,
                  "_"
                )}&code=${code}&details=${state.topic}`}
              />
              <input
                type="submit"
                className={`Button ${
                  state.name === "" || state.email === "" ? "disabled" : ""
                }`}
                value={btnText}
                onClick={handleCopy(
                  `${
                    process.env.REACT_APP_URL
                  }/share?email=${sender}&category=Tool&name=${state.name.replace(
                    /\s+/g,
                    "_"
                  )}&code=${code}&details=${state.topic}`
                )}
              />
            </div>

            <p className="copyCopy">
              Copy your personalized link and send it to a friend on whatever
              channel is best. They get great contractor content, and you get
              BTA points.
            </p>

            <div className="modalFooter">
              <p>
                <strong>TIME IS PRECIOUS&mdash;</strong> for your busy
                contractor friend who could just use an easy win, these tools
                are low-hanging fruit. A quick video overview, a simple guide,
                and an editable tool!
              </p>
              <p>
                <em>
                  Ask them what they're struggling with, and share a Quick Tool
                  that could help; there are 10 that are fan favs @ BTA.
                </em>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ShareTools;
