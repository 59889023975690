import React, { useEffect, useState } from "react";
import axios from "axios";

function useReferralcode({ email }) {
  const [referrerCode, setReferrerCode] = useState(null);

  async function getReferralcode() {
    await axios
      .post(process.env.REACT_APP_API_URL, {
        query: "getReferralcode",
        email: email,
      })
      .then((response) => {
        setReferrerCode(response.data.body[0].referralcode);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getReferralcode();
  }, [email]);
  return referrerCode;
}

export default useReferralcode;
