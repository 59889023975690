import React, { useState } from 'react';
import Assets from '@/components/Assets';

import AlertifyJS from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';

export default () => {
  function logout() {
    AlertifyJS.confirm("Are you sure you want to logout?",
      function () {
        AlertifyJS.success('Ok');
      },
      function () {
        AlertifyJS.error('Cancel');
      }
    )
  }

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
    setActive(!isActive);
  };

  return (
    <header className="App-header">
      <div className="App-header-inner">
        <div className="Logo-Wrapper">
          <a href="https://members.btacademy.com">
            <img src={Assets.logo} className="App-logo" alt="logo" />
            <h1 className="Wordmark">Breakthrough Academy</h1>
          </a>
        </div>

        <nav>
          <ul>
            <li>
              <a target="_blank" href={'https://members.btacademy.com/skillhub/refer-bta'}>
                Refer a Friend
              </a>
            </li>
            <li>
              <a target="_blank" href={'https://members.btacademy.com/playbook/approved-vendors'}>
                Vendors
              </a>
            </li>
            <li>
              <a target="_blank" href={'https://www.btacademy.com/contractor-evolution/'}>
                Contractor Evolution
              </a>
            </li>
          </ul>

          <div className="divider"></div>

          <div className="Tools">
            <a className="memberProfile" target="_blank" href={'https://members.btacademy.com/profile'}>
              <img src={Assets['bta-icon-user']} className="Icon-User" alt="User Icon" />
            </a>
            <a className="memberLogout" onClick={logout}>
              <img src={Assets['bta-icon-logout']} className="Icon-Logout" alt="Logout Icon" />
            </a>
            <a className="memberHome" target="_blank" href={'https://members.btacademy.com/homepage'}>
              <img src={Assets['bta-icon-home']} className="Icon-Home" alt="Home Icon" />
            </a>
          </div>

          <div className="MobileNavIcon" id="mobileNavToggle" onClick={ToggleClass}>
            <svg role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bars" className="svg-inline--fa fa-bars fa-w-14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path></svg>
          </div>
        </nav>
      </div>

      <nav className={isActive ? 'mobileNav' : 'mobileNav active'}>
        <ul>
          <li>
            <a target="_blank" href={'https://members.btacademy.com/skillhub/refer-bta'}>
              Refer a Friend
            </a>
          </li>
          <li>
            <a target="_blank" href={'https://members.btacademy.com/playbook/approved-vendors'}>
              Vendors
            </a>
          </li>
          <li>
            <a target="_blank" href={'https://www.btacademy.com/contractor-evolution/'}>
              Contractor Evolution
            </a>
          </li>
        </ul>

        <div className="Tools">
          <a className="memberProfile" target="_blank" href={'https://members.btacademy.com/profile'}>
            <img src={Assets['bta-icon-user']} className="Icon-User" alt="User Icon" />
          </a>
          <a className="memberLogout" onClick={logout}>
            <img src={Assets['bta-icon-logout']} className="Icon-Logout" alt="Logout Icon" />
          </a>
        </div>
      </nav>
    </header>
  );
};
