/**
 *
 *  Assets
 *    - Loads all Asset files
 *
 *
 **/
const assetFiles = require.context('@/assets/', true);
const assets = {};

//Get the File
assetFiles.keys().forEach( file => {

	//File Name
	const name = file.split('/').pop().replace(/(.*)\.(.*?)$/, "$1").toLowerCase();

	//if SVG
	if( file.indexOf('.svg') > -1 ){
		assets[ name ] = require( '/src/assets/' + file.replace(/\.\//,'') );
	}else{
		assets[ name ] = assetFiles(file)
	}
});

export default assets;
