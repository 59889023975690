import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import useParams from "@/hooks/useParams";
import useUserProfile from "@/hooks/useUserProfile";

import Assets from "@/components/Assets";
import Header from "@/components/Header";
import Footer from "@/components/Footer";


const Leaderboard = () => {
  const [items, setItems] = useState([]);
  const { email, token } = useParams();
  const user = useUserProfile({ email });
  const [displayedItems, setDisplayedItems] = useState(10); // Initially show 3 items

  const handleLoadMore = () => {
    const newDisplayedItems = Math.min(displayedItems + 10, items.length);
    setDisplayedItems(newDisplayedItems);
  };

  async function getData() {
    await axios
      .get(process.env.REACT_APP_LEADERBOARD)
      .then((response) => {
        setItems(response.data.body);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Header />

      <div className="Leaderboard">
        <div className="Header">
          <h1>BTA Referral Leaderboard</h1>
        </div>

        {items.length > 0 && (
          <div className="Standings">
            <div className="Profile-image second">
              <img
                src={
                  items[1]?.["Profile Picture"] !== "No display image"
                    ? items[1]?.["Profile Picture"]
                    : Assets["leaderboard-shield-2"]
                }
                className="profile"
                alt="profile"
              />
              {items[1]?.["Profile Picture"] !== "No display image" && (
                <img
                  className="Profile-shield"
                  src={Assets["leaderboard-shield-empty-2"]}
                  alt="profile"
                />
              )}

              <h2>{items[1]?.Contact}</h2>
            </div>

            <div className="Profile-image first">
              <img
                src={
                  items[0]?.["Profile Picture"] !== "No display image"
                    ? items[0]?.["Profile Picture"]
                    : Assets["leaderboard-shield-1"]
                }
                alt="profile"
              />
              {items[0]?.["Profile Picture"] !== "No display image" && (
                <img
                  className="Profile-shield"
                  src={Assets["shield"]}
                  alt="profile"
                />
              )}
              <h2>{items[0]?.Contact}</h2>
            </div>

            <div className="Profile-image third">
              <img
                src={
                  items[2]?.["Profile Picture"] !== "No display image"
                    ? items[2]?.["Profile Picture"]
                    : Assets["leaderboard-shield-3"]
                }
                alt="profile"
              />
              {items[2]?.["Profile Picture"] !== "No display image" && (
                <img
                  className="Profile-shield"
                  src={Assets["leaderboard-shield-empty-3"]}
                  alt="profile"
                />
              )}

              <h2>{items[2]?.Contact}</h2>
            </div>
          </div>
        )}

        <Link className="Button back" to={`/?email=${email}&token=${token}`}>
          Back to Dashboard
        </Link>

        <div className="currentPoints">
          <span>Your Current Points</span>
          <span>
            <strong>{user?.currentpoints}</strong>
          </span>
        </div>

        <table>
          <thead>
            <tr>
              <th>Ranking</th>
              <th>
                <span>
                  Your Current Points: <strong>{user?.currentpoints}</strong>
                </span>
                Member
              </th>
              <th>
                <span>&nbsp;</span>
                Ranking
              </th>
              <th>
                <span>&nbsp;</span>
                Successful Referrals
              </th>
              <th>
                <span>&nbsp;</span>
                Points
              </th>
            </tr>
          </thead>

          <tbody>
            {items.slice(0, displayedItems).map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <div className="LeaderboardProfile">
                    <img
                      src={
                        item?.["Profile Picture"] !== "No display image"
                          ? item?.["Profile Picture"]
                          : Assets["profile"]
                      }
                    />

                    <div>
                      <span className="Name">{item.Contact}</span>
                      <span className="Company">{item.Company}</span>
                    </div>
                  </div>
                </td>
                <td>{index + 1}</td>
                <td>{item.Referrals}</td>
                <td>{item.Points}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {displayedItems < items.length && ( // Only show button if there are more items
          <button className="Button" onClick={handleLoadMore}>
            Load More
          </button>
        )}
      </div>

      <Footer />
    </div>
  );
};
export default Leaderboard;
