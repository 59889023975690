import React, { useEffect, useState } from "react";
import axios from "axios";

function useReferrals({ email }) {
  const [referrals, setReferrals] = useState([]);

  async function getReferrals() {
    await axios
      .post(process.env.REACT_APP_API_URL, {
        query: "getReferrals",
        email: email,
      })
      .then((response) => {
        setReferrals(response?.data?.body[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getReferrals();
  }, [email]);
  return referrals;
}

export default useReferrals;
