import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";

import Assets from "@/components/Assets";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import ShareClasses from "@/components/modals/classes";
import ShareTools from "@/components/modals/tools";
import SharePodcasts from "@/components/modals/podcasts";

import useParams from "@/hooks/useParams";
import useUserProfile from "@/hooks/useUserProfile";
import useReferralcode from "@/hooks/useReferralcode";
import useReferrals from "@/hooks/useReferrals";

import "lity/dist/lity.min.js";
import "lity/dist/lity.min.css";

const Dashboard = () => {
  //
  const [share, setShare] = useState({
    podcasts: false,
    classes: false,
    tools: false,
  });

  // Get email and token from URL
  const { email, token } = useParams();

  // Get user, referrer code and referrals from API
  const user = useUserProfile({ email });
  const referrerCode = useReferralcode({ email });
  const referrals = useReferrals({ email });

  // Get number of successful, unsuccessful and pending referrals
  const refSuccessNum = referrals.length > 0 ? 
    referrals?.filter((ref) => ref.Status === "Successful").length : 0;
  const refFailedNum = referrals.length > 0 ? 
    referrals?.filter((ref) => ref.Status === "Unsuccessful").length : 0;
  const refPendingNum = referrals.length > 0 ? 
    referrals?.filter((ref) => ref.Status === "Pending").length : 0;

  //Modal Styles
  const customStyles = {
    overlay: {
      background: "rgba(0,0,0,.75)",
      zIndex: 99,
    },
    content: {
      padding: "0",
      border: "none",
      maxWidth: "800px",
      margin: "auto",
      maxHeight: "870px",
    },
  };

  //Close Modal
  function closeModal(type) {
    setShare({
      ...share,
      [type]: !share[type],
    });
  }

  return (
    <div className="App">
      <Header />

      <div className="Main-Content">
        <div className="heroDesktop">
          <div className="Banner-Image-Wrapper">
            <img
              src={Assets["bta-members_drinks-removed"]}
              className="Banner-Image"
              alt=""
            />
          </div>

          <div className="HeroContent">
            <h2>Who makes a good referral?</h2>
            <p>Spoiler Alert! It's Someone Like You.</p>
            <a href="#learnMore" data-lity className="Button">
              Learn More
            </a>
          </div>
        </div>

        <div>
          <div className="Dashboard">
            <div className="Profile">
              <div className="Profile-image">
                {user?.profilePicture &&
                user?.profilePicture !== "No display image" ? (
                  <img src={user.profilePicture} alt="profile" />
                ) : (
                  <img
                    src={Assets["referrals-shield-blue"]}
                    alt="default profile"
                  />
                )}
                <img
                  className="Profile-shield"
                  src={Assets["shield-blue"]}
                  alt="mask"
                />
              </div>

              <h3 className="Large">{user ? user.fullName : "First Last"}</h3>
              <h4>BTA Rewards ID: {referrerCode}</h4>
            </div>

            <hr />

            <div className="Refferals">
              <p>
                Refer a contractor to BTA. When they're approved, you'll earn
                points!
              </p>
              <div className="ButtonGroup">
                <a
                  target="_blank"
                  href="http://members.btacademy.com/skillhub/refer-bta"
                  className="Button"
                  rel="noreferrer"
                >
                  Refer a Friend
                </a>
                <Link
                  className="Button"
                  to={`/leaderboard?email=${email}&token=${token}`}
                >
                  Leaderboard
                </Link>
              </div>
            </div>

            <hr />

            <div className="Balance">
              <h3>My Balance</h3>
              <div>
                <span className="Blue Large">{user?.currentpoints}</span>

                <h4>Points</h4>
                <span className="LifetimePoints">Lifetime Points: {user?.lifetimepoints}</span>
              </div>
              <a
                href="https://9284ee-3.myshopify.com/"
                target="_blank"
                className="Button"
                rel="noreferrer"
              >
                Shop Rewards
              </a>
            </div>

            <hr />

            <div className="Totals">
              <h3>Total Referrals</h3>
              <span className="Blue Large">
                {referrals[0]?.status !== "error" ? referrals.length : 0}
              </span>

              <dl>
                <dt>Pending</dt>
                <dd>{refPendingNum}</dd>
                <dt>Successful</dt>
                <dd>{refSuccessNum}</dd>
                <dt>Unsuccessful</dt>
                <dd>{refFailedNum}</dd>
              </dl>

              <Link
                className="Button"
                to={`/referrals?email=${email}&token=${token}`}
              >
                My Referrals
              </Link>
            </div>
          </div>

          <div className="heroMobile">
            <div className="Banner-Image-Wrapper">
              <img
                src={Assets["bta-members_drinks-removed-mobile"]}
                className="Banner-Image"
                alt=""
              />
            </div>

            <div className="HeroContent">
              <h2>Who makes a good referral?</h2>
              <p>Spoiler Alert! It's Someone Like You.</p>
              <a href="#learnMore" data-lity className="Button">
                Learn More
              </a>
            </div>
          </div>

          <div className="BodyWrapper">
            <div className="Share">
              <h3>Share Content With Industry Friends</h3>
            </div>

            <div className="Tools">
              <div className="Tool">
                <img src={Assets["podcasts"]} alt="Podcasts" />
                <a
                  className="Button"
                  onClick={() => {
                    setShare({
                      ...share,
                      podcasts: !share.podcasts,
                    });
                  }}
                >
                  Podcast
                </a>
              </div>

              <div className="Tool">
                <img src={Assets["web-classes"]} alt="Web Classes" />
                <a
                  className="Button"
                  onClick={() => {
                    setShare({
                      ...share,
                      classes: !share.classes,
                    });
                  }}
                >
                  Web Classes
                </a>
              </div>

              <div className="Tool">
                <img src={Assets["quick-tools"]} alt="Quick Tools" />
                <a
                  href="#toolsShare"
                  className="Button"
                  onClick={() => {
                    setShare({
                      ...share,
                      tools: !share.tools,
                    });
                  }}
                >
                  Quick Tools
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <div id="learnMore" className="lity-hide">
        <div>
          <div>
            <img src={Assets["20230801-dsc02051-resized"]} />
          </div>

          <div className="content">
            <h2>Who Makes a Good Referral?</h2>
            <p>
              <strong>HINT: It's someone like you!</strong>
            </p>
            <p>
              The best referrals are people just like you: Business owners in
              the contracting industry with &gt; $500K annual revenue who want
              to scale, systemize and strategize.
            </p>
            <p>
              If you know someone who would thrive in our network of industry
              peers, embrace the accountability and bring value to the
              Community, refer them to BTA today!
            </p>
            <a
              className="Button"
              href="https://members.btacademy.com/skillhub/refer-bta"
              target="_blank"
            >
              Refer a Friend
            </a>
          </div>
        </div>
      </div>

      <Modal
        isOpen={share.podcasts}
        style={customStyles}
        onRequestClose={() => closeModal("podcasts")}
      >
        <button className="lity-close" onClick={() => closeModal("podcasts")}>
          <svg width="35" height="35" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14Z" class="bg"/>
            <path d="M12.5771 9.04688L14.4502 12.5195L16.3232 9.04688H18.668L15.7764 13.9824L18.7432 19H16.3779L14.4502 15.459L12.5225 19H10.1436L13.1172 13.9824L10.2188 9.04688H12.5771Z" fill="black"/>
          </svg>
        </button>

        {user && (
          <SharePodcasts
            close={() => closeModal("podcasts")}
            sender={email}
            senderName={user?.fullName}
            code={referrerCode}
          />
        )}
      </Modal>

      <Modal
        isOpen={share.tools}
        style={customStyles}
        onRequestClose={() => closeModal("tools")}
      >
        <button className="lity-close" onClick={() => closeModal("tools")}>
          <svg width="35" height="35" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14Z" class="bg"/>
            <path d="M12.5771 9.04688L14.4502 12.5195L16.3232 9.04688H18.668L15.7764 13.9824L18.7432 19H16.3779L14.4502 15.459L12.5225 19H10.1436L13.1172 13.9824L10.2188 9.04688H12.5771Z" fill="black"/>
          </svg>
        </button>
        {user && (
          <ShareTools
            close={() => closeModal("tools")}
            sender={email}
            senderName={user?.fullName}
            code={referrerCode}
          />
        )}
      </Modal>

      <Modal
        isOpen={share.classes}
        style={customStyles}
        onRequestClose={() => closeModal("classes")}
      >
        <button className="lity-close" onClick={() => closeModal("classes")}>
          <svg width="35" height="35" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 14C28 21.732 21.732 28 14 28C6.26801 28 0 21.732 0 14C0 6.26801 6.26801 0 14 0C21.732 0 28 6.26801 28 14Z" class="bg"/>
            <path d="M12.5771 9.04688L14.4502 12.5195L16.3232 9.04688H18.668L15.7764 13.9824L18.7432 19H16.3779L14.4502 15.459L12.5225 19H10.1436L13.1172 13.9824L10.2188 9.04688H12.5771Z" fill="black"/>
          </svg>
        </button>
        {user && (
          <ShareClasses
            close={() => closeModal("classes")}
            sender={email}
            senderName={user?.fullName}
            code={referrerCode}
          />
        )}
      </Modal>
    </div>
  );
};
export default Dashboard;
