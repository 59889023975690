import React from "react";
import { useSearchParams } from "react-router-dom";

function useParams() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = searchParams.get("token");
  return { email, token };
}

export default useParams;
