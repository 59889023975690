import axios from "axios";
import React, { useEffect, useState } from "react";
import useParams from "@/hooks/useParams";

function ProtectedRoute({ children }) {
  const [logined, setLogined] = useState(false);
  const { email, token } = useParams();

  async function checkToken() {
    await axios
      .post(process.env.REACT_APP_API_URL, {
        query: "checkValidationToken",
        email: email,
        token: token,
      })
      .then((response) => {
        if (response.data.body[0].status === "success") {
          setLogined(true);
        } else {
          window.location.replace(process.env.REACT_APP_LOGIN_URL);
        }
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  useEffect(() => {
    if (email && token) {
      checkToken();
    } else {
      window.location.replace(process.env.REACT_APP_LOGIN_URL);
    }
  });

  if (!logined) {
    return <></>;
  } else {
    return children;
  }
}

export default ProtectedRoute;
