import React, { useState } from "react";
import { Link } from "react-router-dom";

import Assets from "@/components/Assets";
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import useParams from "@/hooks/useParams";
import useUserProfile from "@/hooks/useUserProfile";
import useReferralcode from "@/hooks/useReferralcode";
import useReferrals from "@/hooks/useReferrals";

const Referrals = () => {
  const [displayedItems, setDisplayedItems] = useState(10); // Initially show 3 items

  const { email, token } = useParams();
  const user = useUserProfile({ email }) || null;
  const referrerCode = useReferralcode({ email });
  const referrals = useReferrals({ email });

  const refSuccessNum =
    referrals.filter((ref) => ref.Status === "Successful").length || 0;
  const refFailedNum =
    referrals.filter((ref) => ref.Status === "Unsuccessful").length || 0;
  const refPendingNum =
    referrals.filter((ref) => ref.Status === "Pending").length || 0;

  const handleLoadMore = () => {
    const newDisplayedItems = Math.min(displayedItems + 10, referrals.length);
    setDisplayedItems(newDisplayedItems);
  };

  return (
    <div>
      <Header />

      <div className="Referrals">
        <div className="Header">
          <div className="Banner-Image-Wrapper">
            <img src={Assets["referral-header"]} />
          </div>

          <h2>My Refferals</h2>
        </div>

        <div className="referralsProfile">
          <div>
            <div className="Profile-image">
              {user?.profilePicture &&
              user?.profilePicture !== "No display image" ? (
                <img src={user.profilePicture} />
              ) : (
                <img src={Assets["referrals-shield-blue"]} />
              )}

              <img className="Profile-shield" src={Assets["shield-blue"]} />
            </div>

            <div>
              <h2>{user?.fullName}</h2>
              <span>BTA Rewards ID: {referrerCode}</span>
            </div>
          </div>

          <Link className="Button" to={`/?email=${email}&token=${token}`}>
            Back to Dashboard
          </Link>

          <div className="successfulReferrals">
            <span>Successful Referrals: {refSuccessNum}</span>
          </div>
        </div>

        <div className="referralsList" id="referralsList">
          <h2>My Referrals</h2>
          <span>Every person you have ever referred to BTA</span>

          <img src={Assets["arrow"]} className="bounce" />

          <table>
            <thead>
              <tr>
                <th>Person</th>
                <th>Status</th>
                <th>Closing Date</th>
              </tr>
            </thead>

            <tbody>
              {referrals[0]?.status !== "error" &&
                referrals?.slice(0, displayedItems).map((item, index) => (
                  <tr key={index}>
                    <td>
                      <h3>{item.Contact}</h3>
                      <span>{item.Company}</span>
                    </td>
                    <td>
                      <div className="referralStatus">
                        <span className={`pip-${item.Status}`}></span>
                        <span>{item.Status}</span>
                      </div>
                    </td>

                    <td>
                      <span>{item.ClosingDate}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {displayedItems < referrals.length && ( // Only show button if there are more items
          <button className="Button" onClick={handleLoadMore}>
            Load More
          </button>
        )}
      </div>

      <Footer />
    </div>
  );
};
export default Referrals;
