import React from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";

const toolLinks = {
  ideal_candidate_profile:
    "https://www.btacademy.com/cqt/ideal-candidate-profile-job-posting-tool/b.html",
  interview_guide: "https://www.btacademy.com/cqt/interview-guide-tool/",
  training_checklist: "https://www.btacademy.com/cqt/training-checklist-tool/",
  strategic_plan: "https://www.btacademy.com/cqt/strategic-planning-tool/",
  tech_stack: "https://www.btacademy.com/cqt/tech-stack-tool/",
  interview_setup_call:
    "https://www.btacademy.com/cqt/interview-setup-call-tool/",
  employment_agreements:
    "https://www.btacademy.com/cqt/employment-agreement-tool/",
  budget: "https://www.btacademy.com/cqt/budget-tool/",
  reclaim: "https://www.btacademy.com/cqt/reclaim-5-hours-per-week-tool/",
  compensation_incentives:
    "https://www.btacademy.com/cqt/compensation-incentives-tool/",
};

const podcastLink =
  "https://www.btacademy.com/contractor-evolution/#top-episodes";
const classLink = "https://www.btacademy.com/blog-new/#web-classes";
function Share() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const category = searchParams.get("category");
  const name = searchParams.get("name");
  const code = searchParams.get("code");
  const details = searchParams.get("details");
  const topic = searchParams.get("topic");

  if (email && category && name && code && details) {
    axios
      .post(process.env.REACT_APP_API_URL, {
        query: "scorePoints",
        email,
        category,
        name,
        code,
        details,
      })
      .then(function (response) {
        
        if (response.data.body && response?.data?.body[0]?.status === "points added") {
          switch (category) {
            case "Podcast":
              window.location.replace(podcastLink);
              break;
            case "Tool":
              window.location.replace(
                topic
                  ? toolLinks[topic]
                  : "https://www.btacademy.com/contractor-quick-tools/"
              );
              break;
            case "Class":
              window.location.replace(classLink);
              break;
            default:
              break;
          }
        } else {
          console.log("something wrong",response);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  } 

  return <div className="loader">Webpage is redirecting...</div>;
}

export default Share;
