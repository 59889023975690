import { Routes, Route,useMatch,useLocation,useSearchParams } from "react-router-dom";

import Dashboard from "./Dashboard";
import Referrals from "./Referrals";
import Leaderboard from "./Leaderboard";
import Share from "./share";
import ProtectedRoute from "@/components/ProtectedRoute";

function App() {

  const location = useLocation();
  const match = useMatch(location.pathname);
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");

  if(match?.pathname === "/share" ) {
    switch (category) {
      case "Podcast":
         document.title = "The best contractor podcast (IMO)";
        break;
      case "Class":
         document.title = "A web class that's WORTH a watch";
         break;
      case "Tool":
         document.title = "A contractor quick tool to reclaim some sanity";
         break;
      default:
        document.title = "BTA Member Referral Dashboard";
    }
  }else if(match?.pathname === "/leaderboard") {
    document.title = "BTA Member Referral Leaderboard";
  }else {
    document.title = "BTA Member Referral Dashboard";
  }

  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path={"/"}
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path={"/referrals"}
          element={
            <ProtectedRoute>
              <Referrals />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          exact
          path={"/leaderboard"}
          element={
            <ProtectedRoute>
              <Leaderboard />
            </ProtectedRoute>
          }
        ></Route>

        <Route exact path={"/share"} element={<Share />}></Route>
      </Routes>
    </div>
  );
}

export default App;
